import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { getPaginatedData, postData } from '../lib/services/baseServices';
import ReactPaginate from 'react-paginate';
import '../styles/main.css';
import { DatePicker, Select, Tooltip, message } from 'antd';
import { config } from '../lib/helpers/config';
import { getAllCookies, getFormattedDate } from '../lib/helpers/utils';
import cryingGif from '../assets/images/crying.gif';
import Draggable from 'react-draggable';
import Pusher from 'pusher-js';
import queryString from 'query-string';
import FullScreenLoader from '../components/FullScreenLoader';
const { Option } = Select;

const { authToken } = getAllCookies();

const Main = () => {
  const [planBoardData, setPlanBoardData] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sortDirection, setSortDirection] = useState('asc');
  const [orderData, setOrderData] = useState([]);
  const [paginationDropdownOptions, setPaginationDropdownOptions] = useState([
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ]);
  const [truckListColumnData, setTruckListColumnData] = useState([
    { name: 'a_number', options: [] },
    { name: 'chassis_no', options: [] },
    { name: 'license_plate', options: [] },
    { name: 'customer', options: [] },
    { name: 'receipt_date_planned', options: [] },
    { name: 'delivery_date ', options: [] },
    { name: 'model', options: [] },
    { name: 'vestigingen', options: [] },
  ]);
  const [dateListData, setDateListData] = useState([]);
  const scrollRightDivRef = useRef();
  const scrollLeftDivRef = useRef();
  const [scrollType, setScrollType] = useState('');

  const [sortDirectionANumber, setSortDirectionANumber] = useState('');
  const [sortDirectionChassisNummer, setSortDirectionChassisNummer] = useState('');
  const [sortDirectionLicencePlate, setSortDirectionLicensePlate] = useState('');
  const [sortDirectionCustomer, setSortDirectionCustomer] = useState('');
  // const [sortDirectionDateOfSale, setSortDirectionDateOfSale] = useState('');
  const [sortDirectionReceiptDatePlanned, setSortDirectionReceiptDatePlanned] = useState('');
  const [sortDirectionDeliveryDate, setSortDirectionDeliveryDate] = useState('');
  const [sortDirectionModel, setSortDirectionModel] = useState('');
  const [sortDirectionVestigingen, setSortDirectionVestigingen] = useState('');
  const [columnFilterANumber, setColumnFilterANumber] = useState([]);
  const [columnFilterChassisNummer, setColumnFilterChassisNummer] = useState([]);
  const [columnFilterLicensePlate, setColumnFilterLicensePlate] = useState([]);
  const [columnFilterCustomer, setColumnFilterCustomer] = useState([]);
  // const [columnFilterDateOfSale, setColumnFilterDateOfSale] = useState('');
  const [columnFilterReceiptDatePlanned, setColumnFilterReceiptDatePlanned] = useState('');
  const [columnFilterDeliveryDate, setColumnFilterDeliveryDate] = useState('');
  const [columnFilterModel, setColumnFilterModel] = useState([]);
  const [columnFilterVestigingen, setColumnFilterVestigingen] = useState([]);
  const [planBoardStartWeek, setPlanBoardStartWeek] = useState('');
  const [planBoardEndWeek, setPlanBoardEndWeek] = useState('');
  const [planBoardStartYear, setPlanBoardStartYear] = useState('');
  const [planBoardEndYear, setPlanBoardEndYear] = useState('');

  const [selectedFilterDate, setSelectedFilterDate] = useState('');
  const [selectedFilterPlanningTaskId, setSelectedFilterPlannigTaskId] = useState('');

  const [calenderFilterData, setCalenderFilterData] = useState([]);

  const [isWeekDataFetching, setIsWeekDataFetching] = useState(false);
  const [isDateFilterListFetching, setIsDateFilterListFetching] = useState(false);
  const [openCalenderFilterDropdown, setOpenCalenderFilterDropdown] = useState(false);
  const taskParentRef = useRef();
  const leftFilterBarRef = useRef(null);
  const [rightFilterBarHeight, setRightFilterBarHeight] = useState(0);
  const [draggedItemPlanningTaskTrucksId, setDraggedItemPlanningTaskTrucksId] = useState('');
  const [draggedItemDate, setDraggedItemDate] = useState('');
  const [isDragDropOnProcessing, setIsDragDropOnProcessing] = useState(false);
  const [isForceReload, setIsForceReload] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);


  // code for scrolling
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
      getInitialColumnFilterData();
  }, [])

  useEffect(() => {
    if(isForceReload){
      getPlanBoardData();
    }
  }, [perPage, page, columnFilterANumber, columnFilterChassisNummer, columnFilterLicensePlate, columnFilterCustomer, columnFilterReceiptDatePlanned, columnFilterDeliveryDate, columnFilterModel, columnFilterVestigingen, selectedFilterPlanningTaskId, isForceReload]);

  useEffect(() => {
    if (authToken && leftFilterBarRef) {
      setRightFilterBarHeight(leftFilterBarRef.current.clientHeight + 1);
    }
  }, [columnFilterANumber, columnFilterChassisNummer, columnFilterLicensePlate, columnFilterCustomer, columnFilterReceiptDatePlanned, columnFilterDeliveryDate, columnFilterModel, columnFilterVestigingen, isForceReload]);

  const getCalenderViewData = async () => {
    const params = {
      item_per_page: perPage,
      page: page,
      sort_direction_a_number: sortDirectionANumber,
      sort_direction_chassis_no: sortDirectionChassisNummer,
      sort_direction_license_plate: sortDirectionLicencePlate,
      sort_direction_customer: sortDirectionCustomer,
      // sort_direction_date_of_sale: sortDirectionDateOfSale,
      sort_direction_receipt_date_planned: sortDirectionReceiptDatePlanned,
      sort_direction_delivery_date: sortDirectionDeliveryDate,
      sort_direction_model: sortDirectionModel,
      sort_direction_vestigingen: sortDirectionVestigingen,
      column_filter_a_number: columnFilterANumber,
      column_filter_chassis_no: columnFilterChassisNummer,
      column_filter_license_plate: columnFilterLicensePlate,
      column_filter_customer: columnFilterCustomer,
      // column_filter_date_of_sale: columnFilterDateOfSale,
      column_filter_receipt_date_planned: columnFilterReceiptDatePlanned,
      column_filter_delivery_date: columnFilterDeliveryDate,
      column_filter_model: columnFilterModel,
      column_filter_vestigingen: columnFilterVestigingen,
      plannboardStartWeek: planBoardStartWeek,
      plannboardEndWeek: planBoardEndWeek,
      plannboardStartYear: planBoardStartYear,
      plannboardEndYear: planBoardEndYear,
      date_filter: selectedFilterDate,
      planningTaskId: selectedFilterPlanningTaskId,
      renderOnlyCalenderView: 1,
    }

    const response = await getPaginatedData("api/trucks/listing", params);

    if (response) {
      setDateListData(response.dateLists);
    }

  }

  const getPlanBoardData = async () => {
    setIsDataFetching(true)
    const params = {
      item_per_page: perPage,
      page: page,
      sort_direction_a_number: sortDirectionANumber,
      sort_direction_chassis_no: sortDirectionChassisNummer,
      sort_direction_license_plate: sortDirectionLicencePlate,
      sort_direction_customer: sortDirectionCustomer,
      // sort_direction_date_of_sale: sortDirectionDateOfSale,
      sort_direction_receipt_date_planned: sortDirectionReceiptDatePlanned,
      sort_direction_delivery_date: sortDirectionDeliveryDate,
      sort_direction_model: sortDirectionModel,
      sort_direction_vestigingen: sortDirectionVestigingen,
      column_filter_a_number: columnFilterANumber,
      column_filter_chassis_no: columnFilterChassisNummer,
      column_filter_license_plate: columnFilterLicensePlate,
      column_filter_customer: columnFilterCustomer,
      // column_filter_date_of_sale: columnFilterDateOfSale,
      column_filter_receipt_date_planned: columnFilterReceiptDatePlanned,
      column_filter_delivery_date: columnFilterDeliveryDate,
      column_filter_model: columnFilterModel,
      column_filter_vestigingen: columnFilterVestigingen,
      plannboardStartWeek: planBoardStartWeek,
      plannboardEndWeek: planBoardEndWeek,
      plannboardStartYear: planBoardStartYear,
      plannboardEndYear: planBoardEndYear,
      date_filter: selectedFilterDate,
      planningTaskId: selectedFilterPlanningTaskId,
    }
    const response = await getPaginatedData("api/trucks/listing", params);

    if (response) {
      setPageCount(response.totalItems / perPage)
      setPlanBoardData(response);
      setOrderData(prev => response.data);
      setDateListData(response.dateLists);
      setIsDataFetching(false);
      // setTruckListColumnData(Object.keys(response.data[0]).filter(item => item !== "id").map(item => {return {name: item, options: []}}))
    }
  }

  const getInitialColumnFilterData = async () => {
    setIsDataFetching(true);
    const queryParams = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    for (let i = 0; i < truckListColumnData.length; i++) {
      const tempColumn = truckListColumnData[i];

      // setting data from query params
      if (tempColumn.name === 'a_number') {
        if (queryParams?.column_filter_a_number) {
          setColumnFilterANumber(queryParams.column_filter_a_number);
        }
        if (queryParams?.sort_direction_a_number) {
          setSortDirectionANumber(queryParams.sort_direction_a_number);
        }
      }

      if (tempColumn.name === 'chassis_no') {
        if (queryParams?.column_filter_chassis_no) {
          setColumnFilterChassisNummer(queryParams.column_filter_chassis_no);
        }
        if (queryParams?.sort_direction_chassis_no) {
          setSortDirectionChassisNummer(queryParams.sort_direction_chassis_no);
        }
      }

      if (tempColumn.name === 'license_plate') {
        if (queryParams?.column_filter_license_plate) {
          setColumnFilterLicensePlate(queryParams.column_filter_license_plate);
        }
        if (queryParams?.sort_direction_license_plate) {
          setSortDirectionLicensePlate(queryParams.sort_direction_license_plate);
        }
      }

      if (tempColumn.name === 'customer') {
        if (queryParams?.column_filter_customer) {
          setColumnFilterCustomer(queryParams.column_filter_customer);
        }
        if (queryParams?.sort_direction_customer) {
          setSortDirectionCustomer(queryParams.sort_direction_customer);
        }
      }

      if (tempColumn.name === 'model') {
        if (queryParams?.column_filter_model) {
          setColumnFilterModel(queryParams.column_filter_model);
        }
        if (queryParams?.sort_direction_model) {
          setSortDirectionModel(queryParams.sort_direction_model);
        }
      }

      if (tempColumn.name === 'vestigingen') {
        if (queryParams?.column_filter_vestigingen) {
          setColumnFilterVestigingen(queryParams.column_filter_vestigingen);
        }
        if (queryParams?.sort_direction_vestigingen) {
          setSortDirectionVestigingen(queryParams.sort_direction_vestigingen);
        }
      }

      // setting data from query params

      const params = {
        column: tempColumn.name,
        search: '',
      }
      if (tempColumn.name !== "date_of_sale" || tempColumn.name !== "receipt_date_planned") {
        const response = await getPaginatedData("api/trucks/columnSearch", params);

        if (response) {
          const tempTruckListColumnData = truckListColumnData;
          const tempOptions = response.data.map((item, index) => { return { id: `${tempColumn.name}-${item[tempColumn.name]}-${index}}`, value: item[tempColumn.name] } });

          const currentItem = tempTruckListColumnData.filter(item => item.name === tempColumn.name);
          const currentFiltered = tempTruckListColumnData.filter(item => item.name !== tempColumn.name);

          currentItem[0].options = tempOptions;
          setTruckListColumnData([...currentFiltered, ...currentItem]);
        }
      }

    }
    setIsForceReload(true);
  }

  const handleItemPerPageSelect = (e) => {
    setPerPage(e.target.value);
  }

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  }

  const handleWeekScroll = async (type) => {
    const firstWeekNumber = dateListData[0].weekNumber;
    const lastWeekNumber = dateListData[2].weekNumber;
    const firstYear = dateListData[0].year;
    const lastYear = dateListData[2].year;
    let newWeek;
    let newYear;
    if (type === 'right') {
      newWeek = lastWeekNumber === 52 ? 1 : lastWeekNumber + 1;
      newYear = lastWeekNumber === 52 ? lastYear + 1 : lastYear;
    } else if (type === 'left') {
      newWeek = firstWeekNumber === 1 ? 52 : firstWeekNumber - 1;
      newYear = firstWeekNumber === 1 ? firstYear - 1 : firstYear;
    }

    const params = {
      item_per_page: perPage,
      sort_direction_a_number: sortDirectionANumber,
      sort_direction_chassis_no: sortDirectionChassisNummer,
      sort_direction_license_plate: sortDirectionLicencePlate,
      sort_direction_customer: sortDirectionCustomer,
      // sort_direction_date_of_sale: sortDirectionDateOfSale,
      sort_direction_receipt_date_planned: sortDirectionReceiptDatePlanned,
      sort_direction_delivery_date: sortDirectionDeliveryDate,
      sort_direction_model: sortDirectionModel,
      sort_direction_vestigingen: sortDirectionVestigingen,
      column_filter_a_number: columnFilterANumber,
      column_filter_chassis_no: columnFilterChassisNummer,
      column_filter_license_plate: columnFilterLicensePlate,
      column_filter_customer: columnFilterCustomer,
      // column_filter_date_of_sale: columnFilterDateOfSale,
      column_filter_receipt_date_planned: columnFilterReceiptDatePlanned,
      column_filter_delivery_date: columnFilterDeliveryDate,
      column_filter_model: columnFilterModel,
      column_filter_vestigingen: columnFilterVestigingen,
      plannboardScrollWeek: newWeek,
      plannboardScrollYear: newYear,
      date_filter: selectedFilterDate,
      planningTaskId: selectedFilterPlanningTaskId,
    }
    setIsWeekDataFetching(true);
    const response = await getPaginatedData("api/trucks/listing", params);

    if (response) {
      const {dateLists, ...rest} = response;

      if (type === 'right') {
        setDateListData(prev => [...prev, ...dateLists]);
        setPlanBoardData(prev =>{return {...prev, ...rest}});
        setPlanBoardEndWeek(newWeek);
        setPlanBoardEndYear(newYear);
        setPlanBoardStartWeek(() => firstWeekNumber === 52 ? 1 : firstWeekNumber + 1);
        setPlanBoardStartYear(() => firstWeekNumber === 52 ? firstYear + 1 : firstYear);
      } else {
        setDateListData(prev => [...dateLists, ...prev]);
        setPlanBoardData(prev =>{return {...prev, ...rest}});
        setPlanBoardStartWeek(newWeek);
        setPlanBoardStartYear(newYear);
        setPlanBoardEndWeek(() => lastWeekNumber === 1 ? 52 : lastWeekNumber - 1);
        setPlanBoardEndYear(() => lastWeekNumber === 1 ? lastYear - 1 : lastYear);
      }
      // if(type === 'right') {
      //   scrollDivRef.current.scrollIntoView({ behavior: 'smooth', block: "end" });
      // }
      setScrollType(type);
      setTimeout(() => {
        if (type === 'right') {
          setDateListData(prev => prev.slice(-3));
          setScrollType("")
        } else {
          setDateListData(prev => prev.slice(0, 3));
          setScrollType("")
        }
        setIsWeekDataFetching(false);
      }, 1000);

    }
  }

  useEffect(() => {
    if (scrollType === 'right') {
      if (scrollRightDivRef) {
        scrollRightDivRef?.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" });
      }
    } else if (scrollType === 'left') {
      if (scrollLeftDivRef) {
        scrollLeftDivRef?.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" });
      }
    }
  }, [dateListData])

  const handleSort = async (field_name, type) => {
    setPage(1);
    if (field_name === 'a_number') {
      setSortDirectionANumber(type);
    } else if (field_name === "chassis_no") {
      setSortDirectionChassisNummer(type);
    } else if (field_name === "license_plate") {
      setSortDirectionLicensePlate(type);
    } else if (field_name === 'customer') {
      setSortDirectionCustomer(type);
    } else if (field_name === 'receipt_date_planned') {
      setSortDirectionReceiptDatePlanned(type);
    } else if (field_name === 'delivery_date') {
      setSortDirectionDeliveryDate(type);
    } else if (field_name === 'model') {
      setSortDirectionModel(type);
    } else if (field_name === 'vestigingen') {
      setSortDirectionVestigingen(type);
    }

    const params = {
      item_per_page: perPage,
      page: 1,
      sort_direction_a_number: field_name === 'a_number' ? type : sortDirectionANumber,
      sort_direction_license_plate: field_name === 'a_number' ? type : sortDirectionLicencePlate,
      sort_direction_chassis_no: field_name === 'chassis_no' ? type : sortDirectionChassisNummer,
      sort_direction_customer: field_name === 'customer' ? type : sortDirectionCustomer,
      // sort_direction_date_of_sale: field_name === 'date_of_sale' ? type : sortDirectionDateOfSale,
      sort_direction_receipt_date_planned: field_name === 'date_of_sale' ? type : sortDirectionReceiptDatePlanned,
      sort_direction_delivery_date: field_name === 'date_of_sale' ? type : sortDirectionDeliveryDate,
      sort_direction_model: field_name === 'model' ? type : sortDirectionModel,
      sort_direction_vestigingen: field_name === 'vestigingen' ? type : sortDirectionVestigingen,
      column_filter_a_number: columnFilterANumber,
      column_filter_chassis_no: columnFilterChassisNummer,
      column_filter_license_plate: columnFilterLicensePlate,
      column_filter_customer: columnFilterCustomer,
      // column_filter_date_of_sale: columnFilterDateOfSale,
      column_filter_receipt_date_planned: columnFilterReceiptDatePlanned,
      column_filter_delivery_date: columnFilterDeliveryDate,
      column_filter_model: columnFilterModel,
      column_filter_vestigingen: columnFilterVestigingen,
      plannboardStartWeek: planBoardStartWeek,
      plannboardEndWeek: planBoardEndWeek,
      plannboardStartYear: planBoardStartYear,
      plannboardEndYear: planBoardEndYear,
      date_filter: selectedFilterDate,
      planningTaskId: selectedFilterPlanningTaskId,
    }
    const response = await getPaginatedData("api/trucks/listing", params);

    setPage(1);
    if (response) {
      setPageCount(response.totalItems / perPage)
      setOrderData(response.data);
      setDateListData(response.dateLists);
      // setTruckListColumnData(Object.keys(response.data[0]).filter(item => item !== "id").map(item => {return {name: item, options: []}}))
    }
  }

  const handleColumnFilterChange = async (value, field_name) => {
    const params = {
      column: field_name,
      search: value,
    }
    const response = await getPaginatedData("api/trucks/columnSearch", params);

    if (response) {
      const tempOptions = response.data.map((item, index) => { return item ? { id: `${field_name}-${item[field_name]}-${index}}`, value: item[field_name] } : null }).filter(inner => inner !== null);
      const tempTruckListColumnData = truckListColumnData;
      const currentItem = tempTruckListColumnData.filter(item => item.name === field_name);
      const currentFiltered = tempTruckListColumnData.filter(item => item.name !== field_name);

      currentItem[0].options = tempOptions;
      setTruckListColumnData([...currentFiltered, ...currentItem]);
    }
  }

  const handleColumnFilter = (value, field_name) => {
    setPage(1);
    if (field_name === 'a_number') {
      setColumnFilterANumber(value);
    } else if (field_name === "chassis_no") {
      setColumnFilterChassisNummer(value);
    } else if (field_name === "license_plate") {
      setColumnFilterLicensePlate(value);
    } else if (field_name === "license_plate") {
      setColumnFilterLicensePlate(value);
    } else if (field_name === 'customer') {
      setColumnFilterCustomer(value);
    } else if (field_name === 'model') {
      setColumnFilterModel(value);
    } else if (field_name === 'vestigingen') {
      setColumnFilterVestigingen(value);
    }
  }

  const onChangeFilterDatePicker = (date, field_name) => {
    if (field_name === "receipt_date_planned") {
      setColumnFilterReceiptDatePlanned(date ? date.format("DD-MM-YYYY") : "");
    } else if (field_name === "delivery_date") {
      setColumnFilterDeliveryDate(date ? date.format("DD-MM-YYYY") : "");
    }
    setPage(1);
  }

  const handleDateFilterClick = async (date, year) => {
    const dateFormatted = getFormattedDate(date, year);
    const params = {
      date: dateFormatted,
    }

    // if (!calenderFilterData.find(item=> item.date === dateFormatted)) {
    const response = await getPaginatedData("api/trucks/dateFilter", params);

    if (response) {
      setPage(1);
      const tempFilterList = calenderFilterData;
      setCalenderFilterData([...tempFilterList, { date: dateFormatted, data: response.data, selected: [] }]);
    }
    // }

    if (dateFormatted === selectedFilterDate) {
      setOpenCalenderFilterDropdown(!openCalenderFilterDropdown);
    } else {
      setSelectedFilterDate(dateFormatted);
      setOpenCalenderFilterDropdown(true);
    }

  }

  const handleCalanderFilter = async (date, id) => {
    const tempCalenderFilterData = calenderFilterData;
    const currentItem = tempCalenderFilterData.find(item => item.date === date);
    // for single
    const currentFiltered = tempCalenderFilterData.filter(item => item.date !== date).map(item => { return { ...item, selected: [] } });

    // for multiple
    // const currentFiltered = tempCalenderFilterData.filter(item => item.date !== date);
    // const tempSelected = [id, ...currentItem.selected];

    const tempSelected = [id];
    currentItem.selected = tempSelected;
    setCalenderFilterData([...currentFiltered, currentItem]);
    if (selectedFilterPlanningTaskId === id) {
      setSelectedFilterPlannigTaskId("");
    } else {
      setSelectedFilterPlannigTaskId(id);
    }

    setOpenCalenderFilterDropdown(false);
    setPage(1);
  }


  // Dragging works
  const handleDragStart = (e, position, id, isDraggable) => {
    if (!isDraggable) {
      message.error({ content: "Please drag 1st item of a plan", key: "drag-error" });
      return;
    }
    if (id) {
      setDraggedItemPlanningTaskTrucksId(id);
      setDraggedItemDate(e.target.id);
    }
  }

  const handleDragStop = async (e, position) => {
    const currentDate = e.target.id;

    if (currentDate === 'root') {
      message.error({ content: "You can't drag outside plannboard", key: "dragToRoot", duration: 2 });
      setDraggedItemPlanningTaskTrucksId("");
      setDraggedItemDate("");
      return;
    }
    if (currentDate && currentDate !== 'root' && draggedItemPlanningTaskTrucksId) {
      setIsDragDropOnProcessing(true);
      const params = {
        item_per_page: perPage,
        page: page,
        sort_direction_a_number: sortDirectionANumber,
        sort_direction_license_plate: sortDirectionLicencePlate,
        sort_direction_customer: sortDirectionCustomer,
        // sort_direction_date_of_sale: sortDirectionDateOfSale,
        sort_direction_receipt_date_planned: sortDirectionReceiptDatePlanned,
        sort_direction_delivery_date: sortDirectionDeliveryDate,
        sort_direction_model: sortDirectionModel,
        sort_direction_vestigingen: sortDirectionVestigingen,
        column_filter_a_number: columnFilterANumber,
        column_filter_chassis_no: columnFilterChassisNummer,
        column_filter_license_plate: columnFilterLicensePlate,
        column_filter_customer: columnFilterCustomer,
        // column_filter_date_of_sale: columnFilterDateOfSale,
        column_filter_receipt_date_planned: columnFilterReceiptDatePlanned,
        column_filter_delivery_date: columnFilterDeliveryDate,
        column_filter_model: columnFilterModel,
        column_filter_vestigingen: columnFilterVestigingen,
        plannboardStartWeek: planBoardStartWeek,
        plannboardEndWeek: planBoardEndWeek,
        plannboardStartYear: planBoardStartYear,
        plannboardEndYear: planBoardEndYear,
        date_filter: selectedFilterDate,
        planningTaskId: selectedFilterPlanningTaskId,
        renderOnlyCalenderView: 1,
      }

      const data = {
        planning_task_trucks_id: draggedItemPlanningTaskTrucksId,
        start_date: currentDate,
      }

      const response = await postData("api/trucks/movePlanningTasks", data, params);

      if (response) {
        setDateListData(response.dateLists);
        setIsDragDropOnProcessing(false);
      } else {
        setIsDragDropOnProcessing(false);
      }
    }
    setDraggedItemPlanningTaskTrucksId("");
    setDraggedItemDate("");
  }

  useEffect(() => {

    const pusher = new Pusher('342d649b633007d997aa', {
      cluster: 'eu',
    });

    const channel = pusher.subscribe('my-channel');
    channel.bind('my-event', function (data) {
      getCalenderViewData();
    });

    return (() => {
      pusher.unsubscribe("my-channel")
      // pusher.unsubscribe(‘channel_name2’)
    })
  }, []);

  return (
    <FullScreenLoader isLoading={isDataFetching}>
      <div className="col-lg-11 mx-auto p-3 py-md-3">
        <header className="d-flex align-items-center justify-content-between pb-3 mb-5 border-bottom">
          <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
            <img className="me-3" src="assets/images/logo.png" alt="logo" />
            <span className="fs-4">Planboard</span>
          </a>
          <a target={"_blank"} href={`${config.portal_url}/dbtables/trucks/listing`} className="back-to-list-btn">Back to list</a>
        </header>

        <main>

          {
            authToken ? (
              <div>
                <div className={`row justify-content-end custom-sticky-position ${scrollPosition > 296 ? "d-flex" : "d-none"}`} >
                  {/* Working with stickiy header */}
                  <div className="col-xl-6 task-holder">
                    {
                      isDragDropOnProcessing && <div className="loading-overlay">
                        <div className="spinner-border text-secondary"></div>
                      </div>
                    }

                    <a className="slide-day slide-day-left">
                      {
                        isWeekDataFetching
                          ? <div className="spinner-border text-secondary bg-light"></div>
                          : <i className="fa-solid fa-square-caret-left" onClick={() => handleWeekScroll('left')}></i>
                      }
                    </a>
                    <a className="slide-day slide-day-right">
                      {
                        isWeekDataFetching
                          ? <div className="spinner-border text-secondary bg-light"></div>
                          : <i className="fa-solid fa-square-caret-right" onClick={() => handleWeekScroll('right')}></i>
                      }
                    </a>
                    {/* Rendering schedulerdata */}
                    <div className="row calender-schedule-container flex-nowrap" ref={taskParentRef}>
                      {
                        dateListData?.map((data, indexDateListData) => (
                          <div className="col-xl-4" ref={indexDateListData === dateListData.length - 1 ? scrollRightDivRef : indexDateListData === 1 ? scrollLeftDivRef : null}>
                            <div >

                              <div className="day">
                                <div className="row row-heading">
                                  <div className="col-xl-12 text-center week">Week {data?.weekNumber}</div>
                                </div>
                                <div className="row row-heading">
                                  {
                                    data?.dateColumnLists?.map((item, index) => (
                                      <div className="col text-center day px-0">{item.date}</div>
                                    ))
                                  }
                                </div>
                                <div className="row row-heading">
                                  {
                                    data?.dateColumnLists?.map((item, index) => (
                                      <div className="col text-center">{item.weekDay}</div>
                                    ))
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>

                  </div>
                  {/* Working with stickiy header */}
                </div>
                <div className="row">





                  <div className="col-xl-8">
                    <div className="custom-sticky-position">

                      <div className="row row-heading">
                        <div className="col-xl-2">
                          <select className="form-select form-select-sm select-record-per-page" value={perPage} onChange={handleItemPerPageSelect}>
                            {
                              paginationDropdownOptions?.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-xl-10">
                          {perPage} out of {planBoardData?.totalItems ? planBoardData.totalItems : 0} record shown
                        </div>
                      </div>
                      <div className="row row-heading">
                        <div className="col-xl-3"></div>
                        <div className="col-xl-3"></div>
                        <div className="col-xl-3"></div>
                        <div className="col-xl-3"></div>
                      </div>
                      <div className="row row-heading">
                        <div className="filter-item" onClick={() => handleSort("a_number", sortDirectionANumber === 'asc' ? 'desc' : 'asc')}>
                          OM nummer {sortDirectionANumber === "desc"
                            ? <i className="fa-solid fa-arrow-up"></i>
                            : sortDirectionANumber === "asc"
                              ? <i className="fa-solid fa-arrow-down"></i>
                              : null}
                        </div>
                        <div className="filter-item" onClick={() => handleSort("chassis_no", sortDirectionChassisNummer === 'asc' ? 'desc' : 'asc')}>
                          Chassis Nummer {sortDirectionChassisNummer === "desc"
                            ? <i className="fa-solid fa-arrow-up"></i>
                            : sortDirectionChassisNummer === "asc"
                              ? <i className="fa-solid fa-arrow-down"></i>
                              : null}
                        </div>
                        <div className="filter-item" onClick={() => handleSort("license_plate", sortDirectionANumber === 'asc' ? 'desc' : 'asc')}>
                          Kenteken {sortDirectionANumber === "desc"
                            ? <i className="fa-solid fa-arrow-up"></i>
                            : sortDirectionANumber === "asc"
                              ? <i className="fa-solid fa-arrow-down"></i>
                              : null}
                        </div>
                        <div className="filter-item" onClick={() => handleSort("customer", sortDirectionLicencePlate === 'asc' ? 'desc' : 'asc')}>
                          Klantnaam {sortDirectionLicencePlate === "desc"
                            ? <i className="fa-solid fa-arrow-up"></i>
                            : sortDirectionLicencePlate === "asc"
                              ? <i className="fa-solid fa-arrow-down"></i>
                              : null}
                        </div>
                        <div className="filter-item" onClick={() => handleSort("receipt_date_planned", sortDirectionReceiptDatePlanned === 'asc' ? 'desc' : 'asc')}>
                          Binnenkomstdatum {sortDirectionReceiptDatePlanned === "desc"
                            ? <i className="fa-solid fa-arrow-up"></i>
                            : sortDirectionReceiptDatePlanned === "asc"
                              ? <i className="fa-solid fa-arrow-down"></i>
                              : null}
                        </div>
                        <div className="filter-item" onClick={() => handleSort("delivery_date", sortDirectionDeliveryDate === 'asc' ? 'desc' : 'asc')}>
                          Aflever datum {sortDirectionDeliveryDate === "desc"
                            ? <i className="fa-solid fa-arrow-up"></i>
                            : sortDirectionDeliveryDate === "asc"
                              ? <i className="fa-solid fa-arrow-down"></i>
                              : null}
                        </div>
                        {/* <div className="col-xl-3 filter-item" onClick={() => handleSort("date_of_sale", sortDirectionDateOfSale === 'asc' ? 'desc' : 'asc')}>
                        Date of sale {sortDirectionDateOfSale === "desc"
                          ? <i className="fa-solid fa-arrow-up"></i>
                          : sortDirectionDateOfSale === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                      </div> */}
                        <div className="filter-item" onClick={() => handleSort("model", sortDirectionModel === 'asc' ? 'desc' : 'asc')}>
                          Model {sortDirectionModel === "desc"
                            ? <i className="fa-solid fa-arrow-up"></i>
                            : sortDirectionModel === "asc"
                              ? <i className="fa-solid fa-arrow-down"></i>
                              : null}
                        </div>
                        <div className="filter-item" onClick={() => handleSort("vestigingen", sortDirectionVestigingen === 'asc' ? 'desc' : 'asc')}>
                          Vestigingen {sortDirectionVestigingen === "desc"
                            ? <i className="fa-solid fa-arrow-up"></i>
                            : sortDirectionVestigingen === "asc"
                              ? <i className="fa-solid fa-arrow-down"></i>
                              : null}
                        </div>
                      </div>
                    </div>



                    {/* rendering column search field */}
                    <div className="row row-filter row-heading" ref={leftFilterBarRef}>
                      {/* OM Nummer */}
                      <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          {/* <input type="text" className="form-control" placeholder="" aria-describedby="filter" /> */}
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '80%' }}
                            placeholder="Search"
                            filterOption={false}
                            value={columnFilterANumber}
                            onChange={(value) => handleColumnFilter(value, 'a_number')}
                            onSearch={(value) => handleColumnFilterChange(value, 'a_number')}
                          >
                            {
                              truckListColumnData.find(item => item.name === 'a_number')?.options?.map((option, index) => (
                                <Option key={option?.id} value={option.value}>{option.value}</Option>
                              ))
                            }
                          </Select>
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div>

                      {/* Chassis Nummer */}
                      <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          {/* <input type="text" className="form-control" placeholder="" aria-describedby="filter" /> */}
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '80%' }}
                            placeholder="Search"
                            filterOption={false}
                            value={columnFilterChassisNummer}
                            onChange={(value) => handleColumnFilter(value, 'chassis_no')}
                            onSearch={(value) => handleColumnFilterChange(value, 'chassis_no')}
                          >
                            {
                              truckListColumnData.find(item => item.name === 'chassis_no')?.options?.map((option, index) => (
                                <Option key={option?.id} value={option.value}>{option.value}</Option>
                              ))
                            }
                          </Select>
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div>


                      {/* Kenteken   */}
                      <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          {/* <input type="text" className="form-control" placeholder="" aria-describedby="filter" /> */}
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '80%' }}
                            placeholder="Search"
                            filterOption={false}
                            value={columnFilterLicensePlate}
                            onChange={(value) => handleColumnFilter(value, 'license_plate')}
                            onSearch={(value) => handleColumnFilterChange(value, 'license_plate')}
                          >
                            {
                              truckListColumnData.find(item => item.name === 'license_plate')?.options?.map((option, index) => (
                                <Option key={option?.id} value={option.value}>{option.value}</Option>
                              ))
                            }
                          </Select>
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div>

                      {/* Klantnaam    */}
                      <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          {/* <input type="text" className="form-control" placeholder="" aria-describedby="filter" /> */}
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '80%' }}
                            placeholder="Search"
                            filterOption={false}
                            value={columnFilterCustomer}
                            onChange={(value) => handleColumnFilter(value, 'customer')}
                            onSearch={(value) => handleColumnFilterChange(value, 'customer')}
                          >
                            {
                              truckListColumnData.find(item => item.name === 'customer')?.options?.map((option, index) => (
                                <Option key={option?.id} value={option.value}>{option.value}</Option>
                              ))
                            }
                          </Select>
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div>
                      {/* Date of Sale */}
                      {/* <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          <DatePicker style={{ width: '70%' }} onChange={onChangeFilterDatePicker} suffixIcon={null} allowClear format={"DD MMM YYYY"} defaultValue={columnFilterDateOfSale ? moment(columnFilterDateOfSale) : null} />
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div> */}
                      {/* receipt_date_planned */}
                      <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          {/* <input type="text" className="form-control" placeholder="" aria-describedby="filter" /> */}
                          <DatePicker style={{ width: '80%' }} onChange={(date, dateString) => onChangeFilterDatePicker(date, 'receipt_date_planned')} suffixIcon={null} allowClear format={"DD-MM-YYYY"} defaultValue={columnFilterReceiptDatePlanned ? moment(columnFilterReceiptDatePlanned) : null} />
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div>
                      {/* delivery_date */}
                      <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          {/* <input type="text" className="form-control" placeholder="" aria-describedby="filter" /> */}
                          <DatePicker style={{ width: '80%' }} onChange={(date, dateString) => onChangeFilterDatePicker(date, 'delivery_date')} suffixIcon={null} allowClear format={"DD-MM-YYYY"} defaultValue={columnFilterDeliveryDate ? moment(columnFilterDeliveryDate) : null} />
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div>

                      {/* Model */}
                      <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          {/* <input type="text" className="form-control" placeholder="" aria-describedby="filter" /> */}
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '80%' }}
                            placeholder="Search"
                            filterOption={false}
                            value={columnFilterModel}
                            onChange={(value) => handleColumnFilter(value, 'model')}
                            onSearch={(value) => handleColumnFilterChange(value, 'model')}
                          >
                            {
                              truckListColumnData.find(item => item.name === 'model')?.options?.map((option, index) => (
                                <Option key={option?.id} value={option.value}>{option.value}</Option>
                              ))
                            }
                          </Select>
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div>

                      {/* Vestigingen */}
                      <div className={`filter-item-search`}>
                        <div className="input-group mb-3 my-3 d-flex">
                          {/* <input type="text" className="form-control" placeholder="" aria-describedby="filter" /> */}
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '80%' }}
                            placeholder="Search"
                            filterOption={false}
                            value={columnFilterVestigingen}
                            onChange={(value) => handleColumnFilter(value, 'vestigingen')}
                            onSearch={(value) => handleColumnFilterChange(value, 'vestigingen')}
                          >
                            {
                              truckListColumnData.find(item => item.name === 'vestigingen')?.options?.map((option, index) => (
                                <Option key={option?.id} value={option.value}>{option.value}</Option>
                              ))
                            }
                          </Select>
                          <button className="btn btn-outline-secondary" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                      </div>

                    </div>

                    {/* Rendering order data */}
                    {
                      orderData.length > 0 && <div>
                        {
                          orderData?.map((item, index) => (
                            <div className="row row-data" key={item.id}>
                              <div className="filter-item-search text-truncate">{item?.a_number}</div>
                              <div className="filter-item-search text-truncate">{item?.chassis_no}</div>
                              <div className="filter-item-search text-truncate">{item?.license_plate}</div>
                              <div className="filter-item-search text-truncate">{item?.customer}</div>
                              {/* <div className="filter-item-search text-truncate">{item?.date_of_sale ? item?.date_of_sale : ""}</div> */}
                              <div className="filter-item-search text-truncate">{item?.receipt_date_planned ? item?.receipt_date_planned : ""}</div>
                              <div className="filter-item-search text-truncate">{item?.delivery_date ? item?.delivery_date : ""}</div>
                              <div className="filter-item-search text-truncate">{item?.model}</div>
                              <div className="filter-item-search text-truncate">{item?.vestigingen}</div>
                            </div>
                          ))
                        }
                      </div>
                    }



                  </div>
                  <div className="col-xl-4 task-holder">
                    {
                      isDragDropOnProcessing && <div className="loading-overlay">
                        <div className="spinner-border text-secondary"></div>
                      </div>
                    }

                    <a className="slide-day slide-day-left">
                      {
                        isWeekDataFetching
                          ? <div className="spinner-border text-secondary bg-light"></div>
                          : <i className="fa-solid fa-square-caret-left" onClick={() => handleWeekScroll('left')}></i>
                      }
                    </a>
                    <a className="slide-day slide-day-right">
                      {
                        isWeekDataFetching
                          ? <div className="spinner-border text-secondary bg-light"></div>
                          : <i className="fa-solid fa-square-caret-right" onClick={() => handleWeekScroll('right')}></i>
                      }
                    </a>
                    {/* Rendering schedulerdata */}
                    <div className="row calender-schedule-container flex-nowrap" ref={taskParentRef}>
                      {
                        dateListData?.map((data, indexDateListData) => (
                          <div className="col-xl-4" ref={indexDateListData === dateListData.length - 1 ? scrollRightDivRef : indexDateListData === 1 ? scrollLeftDivRef : null}>
                            <div >

                              <div className={`day`}>
                                <div className="row row-heading">
                                  <div className="col-xl-12 text-center week">Week {data?.weekNumber}</div>
                                </div>
                                {/* Rendering week*/}
                                <div className="row row-heading">
                                  {
                                    data?.dateColumnLists?.map((item, index) => (
                                      <div className="col text-center day px-0">{item.date}</div>
                                    ))
                                  }
                                </div>
                                <div className="row row-heading">
                                  {
                                    data?.dateColumnLists?.map((item, index) => (
                                      <div className="col text-center day px-0">{item.weekDay}</div>
                                    ))
                                  }
                                </div>
                              </div>
                              <div className={`row row-heading row-filter`} style={{ height: rightFilterBarHeight, visibility: scrollPosition > 300 ? 'hidden' : 'visible' }}>
                                {
                                  data?.dateColumnLists?.map((item, indexDateColumnList) => {
                                    const formattedDate = getFormattedDate(item.date, data.year);
                                    const currentFilter = calenderFilterData.find(item => item.date === formattedDate);
                                    return <div className="col text-center filter-wrapper px-0">
                                      <i
                                        onClick={() => handleDateFilterClick(item.date, data.year)}
                                        className="fa-solid fa-filter"
                                        style={{
                                          color: currentFilter?.selected?.length > 0
                                            ? 'blue'
                                            : '#56839f'
                                        }}
                                      >
                                      </i>
                                      {
                                        currentFilter &&
                                        <div className={`date-filter-container ${formattedDate === selectedFilterDate && openCalenderFilterDropdown ? "show" : ""}`}>
                                          {
                                            currentFilter.data.map(resultItem => (
                                              <div
                                                // className={`date-filter-content ${currentFilter.selected.includes(resultItem.id) ? "selected" : ""}`} 
                                                className={`date-filter-content ${resultItem.id === selectedFilterPlanningTaskId ? "selected" : ""}`}
                                                key={`${resultItem.id}-${resultItem.code}`}
                                                onClick={() => handleCalanderFilter(formattedDate, resultItem.id)}
                                              >
                                                <div className="code" style={{ backgroundColor: resultItem?.color_bg, color: resultItem?.color_text }}>
                                                  <span>{resultItem?.code}</span>
                                                </div>
                                                <div className="count">
                                                  <span>{resultItem.total}</span>
                                                </div>
                                              </div>
                                            ))
                                          }
                                        </div>
                                      }
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                            <div className="tasks">
                              {
                                data?.trucksLists?.map((item, indexTruckList) => (
                                  <div className="row row-data">
                                    {
                                      item?.dateLists?.map((dateList, indexDateList) => {
                                        const planning_task_details = dateList.planning_task_id ? planBoardData[`planning_task_details_${dateList.planning_task_id}`] : null;
                                        const note_details = dateList.planning_task_id ? planBoardData[`note_lists_${dateList.planning_task_trucks_id}`] : null;
                                        return <Draggable
                                          bounds={taskParentRef}
                                          // offsetParent={taskParentRef}
                                          // axis="x"
                                          position={{ x: 0, y: 0 }}
                                          disabled={dateList?.planning_task_trucks_id ? false : true}
                                          defaultClassNameDragging="dragging-item"
                                          onStart={(e, position) => handleDragStart(e, position, dateList.planning_task_trucks_id, dateList.render_count ? true : false)}
                                          onStop={(e, position) => handleDragStop(e, position)}
                                          // onDrag={(e, position) => handleDrag(e, position, getFormattedDate(data.dateColumnLists[indexDateList].date, data.year))}
                                          onMouseDown={(e) => e.preventDefault()}
                                          id={`${getFormattedDate(data.dateColumnLists[indexDateList].date, data.year)}`}
                                        >
                                          <div id={`${getFormattedDate(data.dateColumnLists[indexDateList].date, data.year)}`} className={`col`}>
                                            <Tooltip
                                              color="#fff"
                                              title={<div className="note-container">

                                                <p className="title">{planning_task_details?.supplier ? planning_task_details?.supplier : "N/A"}</p>
                                                <p className="title">{planning_task_details?.email ? planning_task_details?.email : "N/A"}</p>
                                                <p className="title blue">{planning_task_details?.phone ? planning_task_details?.phone : "N/A"}</p>

                                                <a target="_blank" href={`${config.portal_url}/dbtables/trucks/edit/${item.id}?redirectFrom=planboard`} className="url">Open trucks in new tab</a>
                                                {
                                                  note_details?.length > 0 && note_details.map((note, index) => (
                                                    <>
                                                      <p className="subtitle">On {note.date} {note.name}:</p>
                                                      <p className="title">{note.notes}</p>
                                                    </>
                                                  ))
                                                }

                                                {/* <span className="close-icon">
                                      <GrClose size={16}/>
                                      </span> */}
                                              </div>}
                                              placement="bottom"
                                              overlayInnerStyle={{ width: 323, height: '100%', padding: 0 }}>

                                              <div id={`${getFormattedDate(data.dateColumnLists[indexDateList].date, data.year)}`}
                                                className={`task-box task-blue text-center ${draggedItemPlanningTaskTrucksId === dateList.planning_task_trucks_id ? "dragging-active" : ""}`}
                                                style={
                                                  planning_task_details ? {
                                                    backgroundColor: planning_task_details.color_bg,
                                                    color: planning_task_details.color,
                                                    opacity: !draggedItemPlanningTaskTrucksId || (dateList.planning_task_trucks_id === draggedItemPlanningTaskTrucksId) ? 1 : .3,
                                                  } : {
                                                    opacity: 1,
                                                  }
                                                }
                                              >
                                                {
                                                  dateList.render_count > 0 && note_details?.length > 0 && (
                                                    <span className="comment-count">{note_details[0].count}</span>
                                                  )
                                                }
                                                {planning_task_details ? planning_task_details?.code : ""}
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </Draggable>

                                      })
                                    }
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        ))
                      }
                      {/* <div style={{width: 1}} ref={scrollDivRef}></div> */}
                    </div>

                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-11">
                    <nav aria-label="Page navigation">

                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                      />
                      {/* <ul className="pagination justify-content-end">
                  <li className="page-item disabled">
                    <a className="page-link" href="#" tabIndex="-1" aria-disabled="true"><i className="fa-solid fa-angle-left"></i></a>
                  </li>
                  <li className="page-item disabled">
                    <a className="page-link" href="#" tabIndex="-1" aria-disabled="true"><i className="fa-solid fa-angles-left"></i></a>
                  </li>
                  <li className="page-item"><a className="page-link" href="#">1</a></li>
                  <li className="page-item"><a className="page-link" href="#">2</a></li>
                  <li className="page-item"><a className="page-link" href="#">3</a></li>
                  <li className="page-item">
                    <a className="page-link" href="#"><i className="fa-solid fa-angles-right"></i></a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#"><i className="fa-solid fa-angle-right"></i></a>
                  </li>
                </ul> */}
                    </nav>
                  </div>
                  <div className="col-xl-1">
                    <select className="form-select select-page" value={perPage} onChange={handleItemPerPageSelect}>
                      {
                        paginationDropdownOptions.map((option, index) => (
                          <option key={index} value={option.value}>{option.label}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div className='unauthorized'>
                <p>Sorry!!</p>
                <p>You are not logged in</p>
                <img src={cryingGif} className="crying-gif" />
              </div>
            )
          }

        </main>
        <footer className="pt-3 my-5 text-muted border-top">
          Endroit &copy; 2022
        </footer>
      </div>
    </FullScreenLoader>



  )
}

export default Main;
import axios from "axios";
import { config } from "../helpers/config";
import { getAllCookies } from "../helpers/utils";

// const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3ZvbHZvLnN0YWdpbmcuYm9vMi5ubCIsImF1ZCI6Imh0dHA6Ly9leGFtcGxlLmNvbSIsImlhdCI6MTY1NDY5Mjc5MiwibmJmIjoxNjU0NjkyNzkyLCJhY2NvdW50X2lkIjoiMSJ9.2sgNxK1E8T-H4RCExl84vJ8PX6itUKk4JR_A5GHT8P8";

export const getPaginatedData = async (url, params) => {
  try {
    const { authToken } = getAllCookies();

    const response = await axios.get(`${config.baseUrl}/${url}`, {
      params,
      headers: {
        "Authorization": authToken,
      },
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

export const postData = async (url, data, params) => {
  try {
    const { authToken } = getAllCookies();
    const response = await axios.post(`${config.baseUrl}/${url}`, data, {
      params,
      headers: {
        Authorization: authToken,
      },
    });

    return response.data;
  } catch (err) {
    return err;
  }
};
